const initBlock = ( $block ) => {
	app.loadGsap( ( gsap ) => {
		const timeline = gsap.timeline( {
			scrollTrigger: {
				trigger: $block,
				start: '-=400 top',
				end: 'center center',
				scrub: 0.5,
			}
		} );

		timeline.from( $block, {
			background: 'transparent',
		} );
	} );
};

app.loadBlock( initBlock, 'form', '.js-section-form' );
